/* Roca Thin */
@font-face {
  font-family: "Roca";
  src: url("../src/assets/Font/Roca Thin.ttf") format("truetype");
  font-weight: 100; /* Correspond au poids Thin */
  font-style: normal;
}

@font-face {
  font-family: "Roca";
  src: url("../src/assets/Font/Roca Thin Italic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}

/* Roca Light */
@font-face {
  font-family: "Roca";
  src: url("../src/assets/Font/Roca Light.ttf") format("truetype");
  font-weight: 300; /* Correspond au poids Light */
  font-style: normal;
}

@font-face {
  font-family: "Roca";
  src: url("../src/assets/Font/Roca Light Italic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

/* Roca Regular */
@font-face {
  font-family: "Roca";
  src: url("../src/assets/Font/Roca Regular.ttf") format("truetype");
  font-weight: 400; /* Correspond au poids Regular */
  font-style: normal;
}

@font-face {
  font-family: "Roca";
  src: url("../src/assets/Font/Roca Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

/* Roca Bold */
@font-face {
  font-family: "Roca";
  src: url("../src/assets/Font/Roca Bold.ttf") format("truetype");
  font-weight: 700; /* Correspond au poids Bold */
  font-style: normal;
}

@font-face {
  font-family: "Roca";
  src: url("../src/assets/Font/Roca Bold Italic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}

/* Roca Black */
@font-face {
  font-family: "Roca";
  src: url("../src/assets/Font/Roca Black.ttf") format("truetype");
  font-weight: 900; /* Correspond au poids Black */
  font-style: normal;
}

@font-face {
  font-family: "Roca";
  src: url("../src/assets/Font/Roca Black Italic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

/* Roca Heavy */
@font-face {
  font-family: "Roca";
  src: url("../src/assets/Font/Roca Heavy.ttf") format("truetype");
  font-weight: 800; /* Correspond au poids Heavy */
  font-style: normal;
}

@font-face {
  font-family: "Roca";
  src: url("../src/assets/Font/Roca Heavy Italic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}

/* Poppins Thin */
@font-face {
  font-family: "Poppins";
  src: url("../src/assets/Font/Poppins-Thin.ttf") format("truetype");
  font-weight: 100; /* Correspond au poids Thin */
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../src/assets/Font/Poppins-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}

/* Poppins ExtraLight */
@font-face {
  font-family: "Poppins";
  src: url("../src/assets/Font/Poppins-ExtraLight.ttf") format("truetype");
  font-weight: 200; /* Correspond au poids Extra Light */
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../src/assets/Font/Poppins-ExtraLightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
}

/* Poppins Light */
@font-face {
  font-family: "Poppins";
  src: url("../src/assets/Font/Poppins-Light.ttf") format("truetype");
  font-weight: 300; /* Correspond au poids Light */
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../src/assets/Font/Poppins-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

/* Poppins Regular */
@font-face {
  font-family: "Poppins";
  src: url("../src/assets/Font/Poppins-Regular.ttf") format("truetype");
  font-weight: 400; /* Correspond au poids Regular */
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../src/assets/Font/Poppins-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

/* Poppins Medium */
@font-face {
  font-family: "Poppins";
  src: url("../src/assets/Font/Poppins-Medium.ttf") format("truetype");
  font-weight: 500; /* Correspond au poids Medium */
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../src/assets/Font/Poppins-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

/* Poppins SemiBold */
@font-face {
  font-family: "Poppins";
  src: url("../src/assets/Font/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600; /* Correspond au poids SemiBold */
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../src/assets/Font/Poppins-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

/* Poppins Bold */
@font-face {
  font-family: "Poppins";
  src: url("../src/assets/Font/Poppins-Bold.ttf") format("truetype");
  font-weight: 700; /* Correspond au poids Bold */
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../src/assets/Font/Poppins-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}

/* Poppins ExtraBold */
@font-face {
  font-family: "Poppins";
  src: url("../src/assets/Font/Poppins-ExtraBold.ttf") format("truetype");
  font-weight: 800; /* Correspond au poids Extra Bold */
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../src/assets/Font/Poppins-ExtraBoldItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}

/* Poppins Black */
@font-face {
  font-family: "Poppins";
  src: url("../src/assets/Font/Poppins-Black.ttf") format("truetype");
  font-weight: 900; /* Correspond au poids Black */
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../src/assets/Font/Poppins-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}
