@tailwind base;
@tailwind components;
@tailwind utilities;
@import "leaflet/dist/leaflet.css";
@import "~react-image-gallery/styles/css/image-gallery.css";

body {
  background-color: #f4f1e9;
}

.image-gallery-slide img {
  border-radius: 20px; /* Applique la classe rounded-20 */
}

.image-gallery-thumbnail img {
  border-radius: 9px !important; /* Assure que les images miniatures sont bien arrondies */
  box-shadow: none !important; /* Enlève toute ombre ou effet similaire */
}

.image-gallery-thumbnail.active {
  border: 2px solid #f46036 !important;
  border-radius: 10px;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus {
  /* border: none !important; */
  border: 2px solid #f46036 !important;
  border-radius: 10px;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:hover {
  border: 2px solid #f46036 !important;
  border-radius: 10px;
}

/* Styles pour la barre de défilement */
.lable-scrool::-webkit-scrollbar {
  height: 5px; /* Hauteur de la scrollbar */
}

.lable-scrool::-webkit-scrollbar-thumb {
  background-color: gray; /* Couleur de la barre de défilement */
  border-radius: 5px; /* Coins arrondis */
}

.lable-scrool::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Couleur du fond de la scrollbar */
  border-radius: 5px;
}
